<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/com_vs2.png" alt="" />
      </template>
      <template v-slot:content>MagicLine Solution</template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper class="content-wrapper">
      <template v-slot:header>
        <div class="title_area">
          <div class="t2">
            <strong>디지털치과 토털 솔루션<br /><span>MagicLine</span></strong>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="mt40 value-con-01-bg">
          <div class="value-con-01 text-wrap">
            <div class="value-con-01 text-top">
              <div class="left blue">임상진료가이드</div>
              <div class="right blue">디지털 토털 솔루션</div>
            </div>
            <div class="value-con-01 text-bottom">
              <div class="left blue">연계 시술 시너지</div>
              <div class="right blue">우수한 제품 경쟁력</div>
            </div>
          </div>
        </div>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper-2">
      <template v-slot:header>
        <div class="title_h2">
          <strong>정확하고 체계적인 임상 진료가이드 제공</strong>
        </div>
        <div class="t1 blue">이제 당신도 교정/덴쳐 전문가!!</div>
        <div class="title_h3">
          3차원 CAD/CAM 기술로 정확한 진단 및 치료 결과를 시뮬레이션하여<br />
          정확한 치료계획 수립과 진료를 가능하게 합니다
        </div>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper-3">
      <template v-slot:header>
        <div class="title_area">
          <div class="title_h2"><strong>디지털 토털 솔루션</strong></div>
          <div class="t1 blue">Digital Dentistry, MagicLine</div>
        </div>
      </template>
      <template v-slot:content>
        <div class="con-04-text-wrap">
          <div class="left-text">스캔데이터</div>
          <div class="right-text">CBCT, X-Ray</div>
          <img src="@/assets/images/img-sol-img-05.png" alt="" />
        </div>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper-4">
      <template v-slot:header>
        <div class="title_area">
          <div class="t2"><strong>우수한 제품 경쟁력</strong></div>
          <div class="t1 blue">디지털 진단을 통해 정확하고 빠른 진료</div>
          <div class="t3">
            CAD/CAM 시스템으로 기존 수작업 대비 정밀한 진단 및 치료계획 수립과<br />
            오차를 최소화한 장치제작으로 만족도 높은 치료 결과를 제공합니다.
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div>
          <kendo-data-source ref="datasource" :data="kendoDataSource"></kendo-data-source>
          <kendo-scroll-view
            :ref="'kendoScrollView'"
            :data-source-ref="'datasource'"
            :duration="1000"
            :contentHeight="'100%'"
            :template="`#=data.template#`"
            @Change="onChange"
            v-bind:style="{
              height: '415px',
              width: '100%;',
              border: 'none',
              background: 'none'
            }"
          ></kendo-scroll-view>
          <div class="slider-pager">
            <div class="bx-pager-item" @click="onClick(0)">
              <a :class="{ active: currentPage === 0 }">교정</a>
            </div>
            <div class="bx-pager-item" @click="onClick(1)">
              <a :class="{ active: currentPage === 1 }">특수보철</a>
            </div>
          </div>
        </div>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper-5">
      <template v-slot:header>
        <div class="title_area">
          <div class="title_h2"><strong>연계 시술 시너지</strong></div>
          <div class="t1">교정 / 특수보철 진료 영역의 확대</div>
          <div class="title_h3">디지털교정 및 디지털덴쳐의 연계 시술로 진료 영역을 확대하세요</div>
        </div>
      </template>
      <template v-slot:content>
        <div class="txt_area value-con-05">
          <div class="left-box-50">
            <img src="@/assets/images/img-sol-img-06.png" alt="" />
            <div class="t1">
              <strong class="size-28 blue">디지털교정</strong>
            </div>
          </div>
          <div class="right-box-50">
            <img src="@/assets/images/img-sol-img-07.png" alt="" />
            <div class="t1">
              <strong class="size-28 blue">임플란트 보철</strong>
            </div>
          </div>
        </div>
      </template>
    </content-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { DataSource } from '@/utils/kendo/kendoDataSource';
import { ScrollView, getKendoScrollView } from '@/utils/kendo/kendoScrollView';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';

type KendoDataSource = {
  template: string;
};

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    'kendo-data-source': DataSource,
    'kendo-scroll-view': ScrollView
  },
  setup() {
    const timer = ref<number>();
    const currentPage = ref<number>(0);
    const kendoScrollView = ref<any>();
    const kendoDataSource = ref<Array<KendoDataSource>>([
      {
        template: `<div>
            <img src="${require('@/assets/images/precise1_1.png')}" alt="" />
            <div class="slider-caption01">
              <strong>기존 수작업</strong> - 작업물 불균일 (기술자의 숙련도에 따른 작업 편차)
            </div>
            <div class="slider-caption02">
              <strong>MagicAlign</strong> - 작업물 균일 (디지털 셋업으로 정확한 장치 제작)
            </div>
          </div>`
      },
      {
        template: `<div>
            <img src="${require('@/assets/images/img-brand-value-03.png')}" alt="" />
            <div class="slider-caption01"><strong>구강스캐너, CT로 인상채득</strong></div>
            <div class="slider-caption02"><strong>밀링머신, 3D프린터로 보철물 제작</strong></div>
          </div>`
      }
    ]);

    const setTimer = (nextPage: number): void => {
      timer.value = setTimeout(() => getKendoScrollView(kendoScrollView.value).scrollTo(nextPage), 5000);
    };

    const clearTimer = (): void => clearInterval(timer.value);

    const onChange = (e: any): void => {
      currentPage.value = e.nextPage;
      clearTimer();
      setTimer((currentPage.value + 1) % kendoDataSource.value.length);
    };

    const onClick = (nextPage: number): void => {
      currentPage.value = nextPage;
      getKendoScrollView(kendoScrollView.value).scrollTo(currentPage.value);
    };

    onMounted((): void => {
      setTimer(1);
    });

    onBeforeUnmount((): void => {
      clearTimer();
    });

    const data = { currentPage, kendoDataSource, kendoScrollView };
    const methods = { onChange, onClick };

    return {
      ...data,
      ...methods
    };
  }
});
</script>

<style scoped lang="scss">
@import './MagicLineSolutionView.scss';
</style>
