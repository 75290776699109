
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { DataSource } from '@/utils/kendo/kendoDataSource';
import { ScrollView, getKendoScrollView } from '@/utils/kendo/kendoScrollView';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';

type KendoDataSource = {
  template: string;
};

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    'kendo-data-source': DataSource,
    'kendo-scroll-view': ScrollView
  },
  setup() {
    const timer = ref<number>();
    const currentPage = ref<number>(0);
    const kendoScrollView = ref<any>();
    const kendoDataSource = ref<Array<KendoDataSource>>([
      {
        template: `<div>
            <img src="${require('@/assets/images/precise1_1.png')}" alt="" />
            <div class="slider-caption01">
              <strong>기존 수작업</strong> - 작업물 불균일 (기술자의 숙련도에 따른 작업 편차)
            </div>
            <div class="slider-caption02">
              <strong>MagicAlign</strong> - 작업물 균일 (디지털 셋업으로 정확한 장치 제작)
            </div>
          </div>`
      },
      {
        template: `<div>
            <img src="${require('@/assets/images/img-brand-value-03.png')}" alt="" />
            <div class="slider-caption01"><strong>구강스캐너, CT로 인상채득</strong></div>
            <div class="slider-caption02"><strong>밀링머신, 3D프린터로 보철물 제작</strong></div>
          </div>`
      }
    ]);

    const setTimer = (nextPage: number): void => {
      timer.value = setTimeout(() => getKendoScrollView(kendoScrollView.value).scrollTo(nextPage), 5000);
    };

    const clearTimer = (): void => clearInterval(timer.value);

    const onChange = (e: any): void => {
      currentPage.value = e.nextPage;
      clearTimer();
      setTimer((currentPage.value + 1) % kendoDataSource.value.length);
    };

    const onClick = (nextPage: number): void => {
      currentPage.value = nextPage;
      getKendoScrollView(kendoScrollView.value).scrollTo(currentPage.value);
    };

    onMounted((): void => {
      setTimer(1);
    });

    onBeforeUnmount((): void => {
      clearTimer();
    });

    const data = { currentPage, kendoDataSource, kendoScrollView };
    const methods = { onChange, onClick };

    return {
      ...data,
      ...methods
    };
  }
});
